var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[(_vm.isLoading)?_c('PreLoader'):_vm._e(),_c('form',{attrs:{"autocomplete":"off","method":"post"},on:{"submit":function($event){$event.preventDefault();return _vm.addOrUpdateGrantsConstraintsPlansSlidesDetails()}}},[_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('CustomSelectBox',{attrs:{"className":'col-md-6',"id":'grantTypeToken',"value":_vm.grantsConstraintsPlansSlidDetails.grantTypeToken,"options":_vm.grantTypeTokenOptions,"title":_vm.$t('Grants.grantTypeSelect'),"imgName":'type.svg'},on:{"changeValue":function($event){_vm.grantsConstraintsPlansSlidDetails.grantTypeToken = $event}}}),_c('CustomCheckbox',{attrs:{"value":_vm.grantsConstraintsPlansSlidDetails.sequentialCalculationOfHourFactorAndValue,"title":_vm.$t(
              'GrantsConstraintsPlans.sequentialCalculationOfHourFactorAndValue'
            ),"className":'col-md-6'},on:{"changeValue":function($event){_vm.grantsConstraintsPlansSlidDetails.sequentialCalculationOfHourFactorAndValue =
              $event}}}),_c('CustomInputInt',{attrs:{"className":'col-md-6',"id":'grantsConstraintsMaximumNumberInDay',"value":_vm.grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumNumberInDay,"title":_vm.$t('GrantsConstraintsPlans.MaximumNumberInDay'),"imgName":'number.svg'},on:{"changeValue":function($event){_vm.grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumNumberInDay =
              $event}}}),_c('CustomCheckbox',{attrs:{"className":'col-md-6',"value":_vm.grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumNumberInDayActivationStatus,"title":_vm.$t('GrantsConstraintsPlans.status')},on:{"changeValue":function($event){_vm.grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumNumberInDayActivationStatus =
              $event}}}),_c('CustomInputInt',{attrs:{"className":'col-md-6',"id":'grantsConstraintsMaximumNumberInMonth',"value":_vm.grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumNumberInMonth,"title":_vm.$t('GrantsConstraintsPlans.MaximumNumberInMonth'),"imgName":'number.svg'},on:{"changeValue":function($event){_vm.grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumNumberInMonth =
              $event}}}),_c('CustomCheckbox',{attrs:{"className":'col-md-6',"value":_vm.grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumNumberInMonthActivationStatus,"title":_vm.$t('GrantsConstraintsPlans.status')},on:{"changeValue":function($event){_vm.grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumNumberInMonthActivationStatus =
              $event}}}),_c('CustomInputInt',{attrs:{"className":'col-md-6',"id":'grantsConstraintsMaximumNumberInYear',"value":_vm.grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumNumberInYear,"title":_vm.$t('GrantsConstraintsPlans.MaximumNumberInYear'),"imgName":'number.svg'},on:{"changeValue":function($event){_vm.grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumNumberInYear =
              $event}}}),_c('CustomCheckbox',{attrs:{"className":'col-md-6',"value":_vm.grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumNumberInYearActivationStatus,"title":_vm.$t('GrantsConstraintsPlans.status')},on:{"changeValue":function($event){_vm.grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumNumberInYearActivationStatus =
              $event}}}),_c('CustomInputInt',{attrs:{"className":'col-md-6',"id":'grantsConstraintsMaximumCostInDay',"value":_vm.grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumCostInDay,"title":_vm.$t('GrantsConstraintsPlans.MaximumCostInDay'),"imgName":'money.svg'},on:{"changeValue":function($event){_vm.grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumCostInDay =
              $event}}}),_c('CustomCheckbox',{attrs:{"className":'col-md-6',"value":_vm.grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumCostInDayActivationStatus,"title":_vm.$t('GrantsConstraintsPlans.status')},on:{"changeValue":function($event){_vm.grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumCostInDayActivationStatus =
              $event}}}),_c('CustomInputInt',{attrs:{"className":'col-md-6',"id":'grantsConstraintsMaximumCostInMonth',"value":_vm.grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumCostInMonth,"title":_vm.$t('GrantsConstraintsPlans.MaximumCostInMonth'),"imgName":'money.svg'},on:{"changeValue":function($event){_vm.grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumCostInMonth =
              $event}}}),_c('CustomCheckbox',{attrs:{"className":'col-md-6',"value":_vm.grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumCostInMonthActivationStatus,"title":_vm.$t('GrantsConstraintsPlans.status')},on:{"changeValue":function($event){_vm.grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumCostInMonthActivationStatus =
              $event}}}),_c('CustomInputInt',{attrs:{"className":'col-md-6',"id":'grantsConstraintsMaximumCostInYear',"value":_vm.grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumCostInYear,"title":_vm.$t('GrantsConstraintsPlans.MaximumCostInYear'),"imgName":'money.svg'},on:{"changeValue":function($event){_vm.grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumCostInYear =
              $event}}}),_c('CustomCheckbox',{attrs:{"className":'col-md-6',"value":_vm.grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumCostInYearActivationStatus,"title":_vm.$t('GrantsConstraintsPlans.status')},on:{"changeValue":function($event){_vm.grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumCostInYearActivationStatus =
              $event}}}),_c('CustomInputInt',{attrs:{"className":'col-md-6',"id":'grantsConstraintsMaximumHourFactorInDay',"value":_vm.grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumHourFactorInDay,"title":_vm.$t('GrantsConstraintsPlans.MaximumHourFactorInDay'),"imgName":'number.svg'},on:{"changeValue":function($event){_vm.grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumHourFactorInDay =
              $event}}}),_c('CustomCheckbox',{attrs:{"className":'col-md-6',"value":_vm.grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumHourFactorInDayActivationStatus,"title":_vm.$t('GrantsConstraintsPlans.status')},on:{"changeValue":function($event){_vm.grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumHourFactorInDayActivationStatus =
              $event}}}),_c('CustomInputInt',{attrs:{"className":'col-md-6',"id":'grantsConstraintsMaximumHourFactorInMonth',"value":_vm.grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumHourFactorInMonth,"title":_vm.$t('GrantsConstraintsPlans.MaximumHourFactorInMonth'),"imgName":'number.svg'},on:{"changeValue":function($event){_vm.grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumHourFactorInMonth =
              $event}}}),_c('CustomCheckbox',{attrs:{"className":'col-md-6',"value":_vm.grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumHourFactorInMonthActivationStatus,"title":_vm.$t('GrantsConstraintsPlans.status')},on:{"changeValue":function($event){_vm.grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumHourFactorInMonthActivationStatus =
              $event}}}),_c('CustomInputInt',{attrs:{"className":'col-md-6',"id":'grantsConstraintsMaximumHourFactorInYear',"value":_vm.grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumHourFactorInYear,"title":_vm.$t('GrantsConstraintsPlans.MaximumHourFactorInYear'),"imgName":'number.svg'},on:{"changeValue":function($event){_vm.grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumHourFactorInYear =
              $event}}}),_c('CustomCheckbox',{attrs:{"className":'col-md-6',"value":_vm.grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumHourFactorInYearActivationStatus,"title":_vm.$t('GrantsConstraintsPlans.status')},on:{"changeValue":function($event){_vm.grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumHourFactorInYearActivationStatus =
              $event}}}),_c('TextArea',{attrs:{"className":'col-md-12',"id":'grantsConstraintsPlansSlidesDetailsSlidesDetailsNote',"value":_vm.grantsConstraintsPlansSlidDetails.grantsConstraintsPlansSlidesDetailsSlidesDetailsNote,"title":_vm.$t('notes'),"imgName":'notes.svg'},on:{"changeValue":function($event){_vm.grantsConstraintsPlansSlidDetails.grantsConstraintsPlansSlidesDetailsSlidesDetailsNote =
              $event}}})],1)]),_c('div',{staticClass:"form-footer"},[_c('button',{staticClass:"btn btn-submit",attrs:{"name":"submit","type":"submit"}},[_vm._v(" "+_vm._s(_vm.submitName)+" ")]),_c('router-link',{staticClass:"btn btn-cancel",attrs:{"to":{
          name: 'GrantsConstraintsPlansSlidesDetails',
          params: {
            grantsConstraintsPlansToken:
              _vm.grantsConstraintsPlansSlidDetails.grantsConstraintsPlansToken,
          },
        }}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }