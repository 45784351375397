<template>
  <div class="">
    <PreLoader v-if="isLoading" />

    <form
      autocomplete="off"
      method="post"
      @submit.prevent="addOrUpdateGrantsConstraintsPlansSlidesDetails()"
    >
      <div class="my-card">
        <div class="row">
          <CustomSelectBox
            :className="'col-md-6'"
            :id="'grantTypeToken'"
            :value="grantsConstraintsPlansSlidDetails.grantTypeToken"
            :options="grantTypeTokenOptions"
            v-on:changeValue="
              grantsConstraintsPlansSlidDetails.grantTypeToken = $event
            "
            :title="$t('Grants.grantTypeSelect')"
            :imgName="'type.svg'"
          />
          <CustomCheckbox
            :value="
              grantsConstraintsPlansSlidDetails.sequentialCalculationOfHourFactorAndValue
            "
            v-on:changeValue="
              grantsConstraintsPlansSlidDetails.sequentialCalculationOfHourFactorAndValue =
                $event
            "
            :title="
              $t(
                'GrantsConstraintsPlans.sequentialCalculationOfHourFactorAndValue'
              )
            "
            :className="'col-md-6'"
          />

          <!-- #region MaximumNumber -->
          <CustomInputInt
            :className="'col-md-6'"
            :id="'grantsConstraintsMaximumNumberInDay'"
            :value="
              grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumNumberInDay
            "
            v-on:changeValue="
              grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumNumberInDay =
                $event
            "
            :title="$t('GrantsConstraintsPlans.MaximumNumberInDay')"
            :imgName="'number.svg'"
          />
          <CustomCheckbox
            :className="'col-md-6'"
            :value="
              grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumNumberInDayActivationStatus
            "
            v-on:changeValue="
              grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumNumberInDayActivationStatus =
                $event
            "
            :title="$t('GrantsConstraintsPlans.status')"
          />

          <CustomInputInt
            :className="'col-md-6'"
            :id="'grantsConstraintsMaximumNumberInMonth'"
            :value="
              grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumNumberInMonth
            "
            v-on:changeValue="
              grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumNumberInMonth =
                $event
            "
            :title="$t('GrantsConstraintsPlans.MaximumNumberInMonth')"
            :imgName="'number.svg'"
          />
          <CustomCheckbox
            :className="'col-md-6'"
            :value="
              grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumNumberInMonthActivationStatus
            "
            v-on:changeValue="
              grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumNumberInMonthActivationStatus =
                $event
            "
            :title="$t('GrantsConstraintsPlans.status')"
          />

          <CustomInputInt
            :className="'col-md-6'"
            :id="'grantsConstraintsMaximumNumberInYear'"
            :value="
              grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumNumberInYear
            "
            v-on:changeValue="
              grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumNumberInYear =
                $event
            "
            :title="$t('GrantsConstraintsPlans.MaximumNumberInYear')"
            :imgName="'number.svg'"
          />
          <CustomCheckbox
            :className="'col-md-6'"
            :value="
              grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumNumberInYearActivationStatus
            "
            v-on:changeValue="
              grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumNumberInYearActivationStatus =
                $event
            "
            :title="$t('GrantsConstraintsPlans.status')"
          />
          <!-- #endregion MaximumNumber -->

          <!-- #region MaximumCost -->
          <CustomInputInt
            :className="'col-md-6'"
            :id="'grantsConstraintsMaximumCostInDay'"
            :value="
              grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumCostInDay
            "
            v-on:changeValue="
              grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumCostInDay =
                $event
            "
            :title="$t('GrantsConstraintsPlans.MaximumCostInDay')"
            :imgName="'money.svg'"
          />
          <CustomCheckbox
            :className="'col-md-6'"
            :value="
              grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumCostInDayActivationStatus
            "
            v-on:changeValue="
              grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumCostInDayActivationStatus =
                $event
            "
            :title="$t('GrantsConstraintsPlans.status')"
          />

          <CustomInputInt
            :className="'col-md-6'"
            :id="'grantsConstraintsMaximumCostInMonth'"
            :value="
              grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumCostInMonth
            "
            v-on:changeValue="
              grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumCostInMonth =
                $event
            "
            :title="$t('GrantsConstraintsPlans.MaximumCostInMonth')"
            :imgName="'money.svg'"
          />
          <CustomCheckbox
            :className="'col-md-6'"
            :value="
              grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumCostInMonthActivationStatus
            "
            v-on:changeValue="
              grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumCostInMonthActivationStatus =
                $event
            "
            :title="$t('GrantsConstraintsPlans.status')"
          />

          <CustomInputInt
            :className="'col-md-6'"
            :id="'grantsConstraintsMaximumCostInYear'"
            :value="
              grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumCostInYear
            "
            v-on:changeValue="
              grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumCostInYear =
                $event
            "
            :title="$t('GrantsConstraintsPlans.MaximumCostInYear')"
            :imgName="'money.svg'"
          />
          <CustomCheckbox
            :className="'col-md-6'"
            :value="
              grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumCostInYearActivationStatus
            "
            v-on:changeValue="
              grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumCostInYearActivationStatus =
                $event
            "
            :title="$t('GrantsConstraintsPlans.status')"
          />
          <!-- #endregion MaximumCost -->

          <!-- #region MaximumHourFactor -->
          <CustomInputInt
            :className="'col-md-6'"
            :id="'grantsConstraintsMaximumHourFactorInDay'"
            :value="
              grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumHourFactorInDay
            "
            v-on:changeValue="
              grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumHourFactorInDay =
                $event
            "
            :title="$t('GrantsConstraintsPlans.MaximumHourFactorInDay')"
            :imgName="'number.svg'"
          />
          <CustomCheckbox
            :className="'col-md-6'"
            :value="
              grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumHourFactorInDayActivationStatus
            "
            v-on:changeValue="
              grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumHourFactorInDayActivationStatus =
                $event
            "
            :title="$t('GrantsConstraintsPlans.status')"
          />

          <CustomInputInt
            :className="'col-md-6'"
            :id="'grantsConstraintsMaximumHourFactorInMonth'"
            :value="
              grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumHourFactorInMonth
            "
            v-on:changeValue="
              grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumHourFactorInMonth =
                $event
            "
            :title="$t('GrantsConstraintsPlans.MaximumHourFactorInMonth')"
            :imgName="'number.svg'"
          />
          <CustomCheckbox
            :className="'col-md-6'"
            :value="
              grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumHourFactorInMonthActivationStatus
            "
            v-on:changeValue="
              grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumHourFactorInMonthActivationStatus =
                $event
            "
            :title="$t('GrantsConstraintsPlans.status')"
          />

          <CustomInputInt
            :className="'col-md-6'"
            :id="'grantsConstraintsMaximumHourFactorInYear'"
            :value="
              grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumHourFactorInYear
            "
            v-on:changeValue="
              grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumHourFactorInYear =
                $event
            "
            :title="$t('GrantsConstraintsPlans.MaximumHourFactorInYear')"
            :imgName="'number.svg'"
          />
          <CustomCheckbox
            :className="'col-md-6'"
            :value="
              grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumHourFactorInYearActivationStatus
            "
            v-on:changeValue="
              grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumHourFactorInYearActivationStatus =
                $event
            "
            :title="$t('GrantsConstraintsPlans.status')"
          />
          <!-- #endregion MaximumHourFactor -->

          <TextArea
            :className="'col-md-12'"
            :id="'grantsConstraintsPlansSlidesDetailsSlidesDetailsNote'"
            :value="
              grantsConstraintsPlansSlidDetails.grantsConstraintsPlansSlidesDetailsSlidesDetailsNote
            "
            v-on:changeValue="
              grantsConstraintsPlansSlidDetails.grantsConstraintsPlansSlidesDetailsSlidesDetailsNote =
                $event
            "
            :title="$t('notes')"
            :imgName="'notes.svg'"
          />
        </div>
      </div>

      <div class="form-footer">
        <button name="submit" type="submit" class="btn btn-submit">
          {{ submitName }}
        </button>
        <router-link
          :to="{
            name: 'GrantsConstraintsPlansSlidesDetails',
            params: {
              grantsConstraintsPlansToken:
                grantsConstraintsPlansSlidDetails.grantsConstraintsPlansToken,
            },
          }"
          class="btn btn-cancel"
        >
          {{ $t("cancel") }}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import CustomSelectBox from "@/components/general/CustomSelectBox.vue";
import CustomInputInt from "@/components/general/CustomInputInt.vue";
import CustomCheckbox from "@/components/general/CustomCheckbox.vue";
import TextArea from "@/components/general/TextArea.vue";
import { setDataMultiLang } from "@/utils/functions";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  mixins: [createToastMixin],
  components: {
    PreLoader,
    CustomSelectBox,
    CustomInputInt,
    CustomCheckbox,
    TextArea,
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      grantTypeTokenOptions: [],
    };
  },
  props: ["grantsConstraintsPlansSlidDetails", "submitName"],
  methods: {
    async getDialogOfGrantTypes() {
      this.isLoading = true;
      this.grantTypeTokenOptions = [];
      try {
        let itemsData =
          this.$store.getters.userData.constantLists.listGrantType;
        for (let item in itemsData) {
          this.grantTypeTokenOptions.push({
            value: itemsData[item]["itemToken"],
            text: setDataMultiLang(
              this.language,
              itemsData[item]["itemNameAr"],
              itemsData[item]["itemNameEn"]
            ),
          });
        }
      } catch (error) {
        this.grantTypeTokenOptions = [];
      }
      this.isLoading = false;
    },
    addOrUpdateGrantsConstraintsPlansSlidesDetails() {
      this.$emit("addOrUpdateGrantsConstraintsPlansSlidesDetails");
    },
  },
  watch: {},
  created() {
    this.getDialogOfGrantTypes();
  },
};
</script>
